u1-ico {
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  --line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. */
  text-rendering: optimizeLegibility; /* Support for Safari and Chrome. */
  -moz-osx-font-smoothing: grayscale; /* Support for Firefox. */
  font-feature-settings: 'liga'; /* Support for IE. */
  font-family: var(--u1-ico-font) !important;

  fill:currentColor; /* for svg, dont use `u1-ico > svg { fill:currentColor }` because it can have the attribute fill="none" */

  /* ok? */
  display: inline-block; /* inline-flex has an other baseline? */
  text-align:center;
  vertical-align:middle;
  font-size: 1.5rem; /* 24px Preferred icon size, material-icons */
  /* overflow:hidden
    + prevents layout-shift on font load.
    - But baseline gets to the box instead of the text.
    - Some Icons on some Fonts may get cuted!
  */
}
u1-ico[icon]:before { /* todo if supported: :where([icon]::before) { */
    content:attr(icon);
}


html { /* use utf8-icons as default */
  /*--u1-ico-font:sans-serif;*/
  --u1-ico-font:'Segoe UI Symbol', 'Noto Emoji', sans-serif; /* noto on linux? */
}
/*
chrome does not need :after-trick (why?) , but ff
not working in safari :(
*
u1-ico:after {
  content: "\00FE0E";
  font:inherit;
  /* for safari (it displays a stupid rect) *
  opacity:0;
}
/*
proposed features to archive monochrome icons
u1-ico {
  text-transform: text;           /* proposed *
  font-variant-emoji: text;       /* proposed *
  font-variant-color: monochrome; /* proposed *
  font-color: monochrome;         /* proposed *
  font-palette: dark;             /* drafted for CSS Fonts Level 4 *
}
*/

/* inline */
u1-ico[inline] {
  overflow:visible;
  font-size:1em;
  vertical-align: text-bottom; /* better middle? */
  /* display: contents; todo? */
  /* margin:auto;  todo: dont stretch if its a flex-item? */
}




/* js loaded icons */
u1-ico > svg {
  xdisplay:block;
  xvertical-align:middle;
  vertical-align:bottom;
  height:1em;
  width:auto;
  min-width:1em;
  overflow:visible;
  margin:auto;
}

/* */
u1-ico[state]:before,
u1-ico[state]:after {
  display:none;
}
u1-ico[state] > svg {
  xdisplay:block;
  xheight:1em;
  xmin-width:1em;
  xoverflow:visible;
  xmargin:auto;
}
u1-ico[state=failed]:before {
  display:block;
}
/* */

/*
[u1-ico-before]::before,
[u1-ico-after]::after {
    /* fine *
    font-family: var(--u1-ico-font);
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. *
    text-rendering: optimizeLegibility; /* Support for Safari and Chrome. *
    -moz-osx-font-smoothing: grayscale; /* Support for Firefox. *
    font-feature-settings: 'liga'; /* Support for IE. */
    /* ok? *
    display: inline-block;
    line-height: 1;
    vertical-align: text-bottom; /* needed? *
}
[u1-ico-before]::before {
    content:attr(u1-ico-before);
    margin-right:.2em;
}
[u1-ico-after]::after {
    content:attr(u1-ico-after);
    margin-left:.1em;
}
*/


/* js | better generate in js? *
html.u1-ico-js u1-ico { width:1em; }
html.u1-ico-ready u1-ico { width: auto; }

u1-ico[failed] {
  overflow:visible;
  width:auto;
  --u1-ico-font:initial;
  xxxfont-family:inherit;
}

/* USEFUL? *
u1-ico {
  font-size:var(--u1-ico-size):
  xoverflow: hidden;
}
*/